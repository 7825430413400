import { request } from '@/utils/request'

// 登录获取nonce
export function setHomeConfig(data) {
    return request({
        url: '/config/home/set',
        method: 'post',
        data
    })
}

// 内容配置
export function getHomeConfig(parameter) {
    return request({
        url: '/config/home/get',
        method: 'get',
        params:parameter
    })
}

// 根据板块获取最新的汇总明细
export function getSummaryDetailsList (parameter) {
    return request({
      url: '/summary/details/list',
      method: 'get',
      params:parameter
    })
}

// 根据板块获取最新的汇总明细
export function getSumResult (data) {
    return request({
      url: '/summary/result',
      method: 'post',
      data
    })
}

// 根据板块获取最新的汇总明细
export function postTest (data) {
    return request({
      url: '/x/test',
      method: 'post',
      data
    })
}
