<template>
	<div>
		<a-row :gutter="24" type="flex">
			<a-col :span="24" class="mb-24">
				<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0, }">
					<template #title>
						<a-row type="flex" align="middle">
							<a-col :span="24" :md="12">
								<h5 class="font-semibold m-0">板块管理</h5>
							</a-col>
							<a-col class="function" :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
								<a-button size="small" class="green-button" @click="add(0)">
									<svg t="1703348571162" class="icon" viewBox="0 0 1024 1024" version="1.1"
										xmlns="http://www.w3.org/2000/svg" p-id="5696" width="12" height="12">
										<path
											d="M512 1024a512 512 0 1 1 512-512 512 512 0 0 1-512 512zM512 128a384 384 0 1 0 384 384 384 384 0 0 0-384-384z m64 640H448V576H256V448h192V256h128v192h192v128H576v192z"
											fill="#36ab60" p-id="5697" data-spm-anchor-id="a313x.search_index.0.i14.c1da3a81bsZ3GH"
											class="selected"></path>
									</svg>
									<span>新增一级板块</span>
								</a-button>
								<a-button @click="exportExcel" size="small">导出</a-button>
							</a-col>

              <a-col :span="24">
                <div class="display-content" v-html="content"></div>
              </a-col>
						</a-row>
					</template>
					<a-table :columns="table1Columns" :data-source="table1Data" :pagination="false" :rowKey="record => record.id">
						<template slot="author" slot-scope="author">
							<div class="table-avatar-info">
								<h6>{{ author.name }}</h6>
							</div>
						</template>
						<template slot="action" slot-scope="row">
							<a-button v-if="row.parentId == 0" type="link" size="small" :data-id="row.key" @click="add(row.id)">
								<span style="color: #36ab60;">新增二级板块</span>
							</a-button>
							<!-- <a-button v-if="row.parentId != 0" type="link" size="small" :data-id="row.key" @click="prompt(row)">
								<span style="color: #faad14;">指令</span>
							</a-button> -->
							<a-button type="link" size="small" :data-id="row.key" @click="edit(row)">
								<span>编辑</span>
							</a-button>
							<a-button type="link" size="small" :data-id="row.key" @click="del(row)">
								<span class="text-danger">删除</span>
							</a-button>
						</template>
					</a-table>
				</a-card>
			</a-col>
		</a-row>
		<a-modal :visible="visible.add.show" title="新增板块" @ok="handleSubmit" @cancel="visible.add.show = false">
			<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" ref="form">
				<a-form-item label="板块名称">
					<a-input v-decorator="['name', { rules: [{ required: true, message: '请输入板块名称' }] }]" />
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal :visible="visible.edit.show" title="编辑板块" @ok="handleEditSubmit" @cancel="visible.edit.show = false">
			<a-form :form="editform" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" ref="editform">
				<a-form-item label="板块名称">
					<a-input v-decorator="['name', { rules: [{ required: true, message: '请输入板块名称' }] }]" />
				</a-form-item>
			</a-form>
		</a-modal>

		<!-- <a-modal :visible="visible.prompt.show" title="chatgpt汇总指令" @ok="handlePromptSubmit"
			@cancel="visible.prompt.show = false">
			<a-form :form="promptform" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" ref="promptform">
				<a-form-item label="指令">
					<a-textarea v-decorator="['prompt', { rules: [{ required: true, message: '请输入指令' }] }]"
						:auto-size="{ minRows: 6, maxRows: 10 }" />
				</a-form-item>
			</a-form>
		</a-modal> -->
	</div>
</template>

<script>

const table1Columns = [
	{
		title: '板块名称',
		dataIndex: 'name',
		scopedSlots: { customRender: 'name' },
		width:200
	},
	// {
	// 	title: '指令',
	// 	dataIndex: 'prompt',
	// 	scopedSlots: { customRender: 'prompt' },
	// },

	{
		scopedSlots: { customRender: 'action' },
		key: 'action',
		width: 220,
	},
];
const key = 'add';
import { getHomeConfig } from "@/api/config.js";
import { exportClassify, getClassifyTree, addClassify, delClassify, updateClassify, updatePromptClassify } from "@/api/service.js";
export default ({
	components: {
	},
	data() {
		return {
			initialValues: {
				name: '123'
			},
			table1Data: [],
			table1Columns: table1Columns,
			visible: {
				add: {
					show: false,
					parentId: null
				},
				edit: {
					show: false,
					id: null,
					name: ''
				},
				prompt: {
					show: false,
					id: null,
					prompt: ''
				},
			},
			classifyName: '',
			form: this.$form.createForm(this),
			editform: this.$form.createForm(this),
			promptform: this.$form.createForm(this),
      content: ''
		}
	},
	mounted() {
		this.getList(true);
		this.getHomeConfig();
	},
	methods: {
    getHomeConfig() {
      getHomeConfig({ type: 'classify' }).then(res => {
        let data = res.data.data;
        if (data.length > 0) {
          this.content = data[0].content;
        }
      })
    },
		exportExcel() {
			exportClassify().then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'follow-data.xlsx'); // 文件名
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link); // 清理DOM
				window.URL.revokeObjectURL(url);  // 释放URL 对象
			})
				.catch((error) => {
					console.error('导出数据时出错:', error);
				});
		},
		handlePromptSubmit() {
			this.$message.loading({ content: '正在保存', key });
			this.promptform.validateFields((err, values) => {
				if (!err) {
					values.id = this.visible.prompt.id;
					updatePromptClassify(values).then(res => {
						this.$message.success({ content: '配置成功', key, duration: 2 });
						this.visible.prompt.show = false;
						this.getList();
					}).catch(err => {
						this.$message.error({ content: '配置失败！' + err, key, duration: 2 });
					})
				}
			});
		},
		handleEditSubmit() {
			this.$message.loading({ content: '正在保存', key });
			this.editform.validateFields((err, values) => {
				if (!err) {
					values.id = this.visible.edit.id;
					updateClassify(values).then(res => {
						this.$message.success({ content: '编辑成功', key, duration: 2 });
						this.visible.edit.show = false;
						this.getList();
					}).catch(err => {
						this.$message.error({ content: '编辑失败！' + err, key, duration: 2 });
					})
				}
			});
		},
		handleSubmit() {
			this.form.validateFields((err, values) => {
				if (!err) {
					this.$message.loading({ content: '正在新增', key });
					values.parentId = this.visible.add.parentId;
					addClassify(values).then(res => {
						this.$message.success({ content: '新增成功', key, duration: 2 });
						this.visible.add.show = false;
						this.getList();
					}).catch(err => {
						// this.$message.error({ content: '新增失败！' + err, key, duration: 2 });
					})
				}
			});
		},
		getList(flag) {
			if (flag) {
				this.$message.loading({ content: '加载中...', key });
			}
			getClassifyTree().then(res => {
				this.table1Data = res.data.data;
				if (flag) {
					this.$message.destroy(key);
				}

			})
		},
		prompt(row) {
			this.visible.prompt.show = true;
			this.visible.prompt.id = row.id;
			this.$nextTick(() => {
				if (this.promptform) {
					this.promptform.setFieldsValue({ prompt: row.prompt });
				}
			});
		},
		edit(row) {
			this.visible.edit.show = true;
			this.visible.edit.id = row.id;
			this.$nextTick(() => {
				if (this.editform) {
					this.editform.setFieldsValue({ name: row.name });
				}
			});
		},
		add(parentId) {
			this.visible.add.show = true;
			this.visible.add.parentId = parentId;
			this.$nextTick(() => {
				if (this.form) {
					this.form.resetFields();
				}
			});
		},
		del(row) {
			let that = this;
			this.$confirm({
				title: '你确认要删除此板块?',
				okText: '确认',
				okType: 'danger',
				cancelText: '取消',
				onOk() {
					delClassify({ id: row.id, parentId: row.parentId }).then(res => {
						that.$message.success({ content: '删除成功', duration: 2 });
						that.getList();
					})
				},
				onCancel() {
				},
			});
		}
	}
})

</script>

<style lang="scss" scoped>
::v-deep .display-content {
  p {
    margin-bottom: 0;
  }
}
</style>